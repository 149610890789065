.sidebar-container {
  background-color: #ffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000;
  padding-right: 20px;
  min-width: 250px;
}

.header {
  position: fixed;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 999px;
}

.header-wrapper {
  top: 10px;
  left: 20px;
  position: absolute;
}

.search-results {
  margin-top: 20px;
}

.sidebar-toggle {
  padding-right: 10px;
}

.search-container {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
}

.search-input {
  padding: 5px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.search-button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.search-button:hover {
  background-color: #0056b3;
}

.sidebar-icon {
  width: 20px;
  height: auto;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  color: #70757a;
}

p {
  margin: 0;
  padding: 0;
}

.land-card {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.desc {
  font-size: 15px;
  color: #70757a;
  margin-top: 5px;
  margin-bottom: 5px;
}

.long-desc {
  font-size: 12px;
  color: #70757a;
  margin-top: 5px;
  margin-bottom: 5px;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.button-styles {
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
}

.sidebar-icon.loading {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.black {
  color: black;
}

@media only screen and (min-width: 769px) {
  /* desktop screen */
  .menu-button {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* mobile screen */
  .hide {
    display: none;
  }
  .search-results {
    margin-top: 60px;
  }
  .menu-button {
    z-index: 1001;
  }

  .go-bottom {
    top: 80px;
    position: absolute;
  }
}
