.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  text-align: center;
}

.input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
}

.button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #006386;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.logo {
  width: 150px;
}
