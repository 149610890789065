.user-card {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.land-card-item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.desc {
  font-size: 15px;
  color: #70757a;
  margin-top: 5px;
  margin-bottom: 5px;
}

.long-desc {
  font-size: 12px;
  color: #70757a;
  margin-top: 5px;
  margin-bottom: 5px;
}

.black {
  color: black;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colored {
  background-color: #d2eaf3;
  padding: 10px;
  border-radius: 10px;
}
